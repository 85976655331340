<template>
  <UDropdown :items="items" :ui="ui">
    <slot />

    <template #item="{ item }">
      <OsIcon v-if="item.icon" :name="item.icon" :class="item.iconClass" />
      <span class="truncate">{{ item.label }}</span>
      <transition name="fade">
        <span v-if="item.suffix" class="text-gray-400 font-medium">
          {{ item.suffix }}
        </span>
      </transition>
    </template>

    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" aria-label="button" role="button" />
    </template>
  </UDropdown>
</template>

<script setup lang="ts">
import type { OsDropdownItem } from '#os-ui/types'

export interface Props {
  items: OsDropdownItem[][]
}

defineProps<Props>()

const ui = {
  background: 'bg-white/90 backdrop-blur dark:bg-gray-950 dark:text-white',
  padding: 'py-4 px-1 flex flex-wrap',
  rounded: 'rounded-2xl',
  shadow: 'shadow',
  ring: 'ring-0',
  width: 'w-72',
  item: {
    size: 'text-md',
    padding: 'px-7 py-4',
    inactive: 'text-gray-500'
  },
  transition: {
    enterActiveClass: 'origin-top-right'
  }
}
</script>
